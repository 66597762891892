import { combineReducers } from "redux";
import userReducer from "./userReducer";
import merchantReducer from "./merchantReducer";
import productReducer from "./productReducer";
import customerEngagementReducer from "./customerEngagementReducer";

export default combineReducers({
  userReducer,
  merchantReducer,
  productReducer,
  customerEngagementReducer,
});
