import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import loginLogo from "../assets/loginLogo.png";
import { postReqwithoutHeader } from "../utils/constant";
import debounce from "lodash.debounce";
import SpinnerComponent from "../components/SpinnerComponent";

const ResetPassword = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [userPassword, setUserPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [formError, setFormError] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserPassword({ ...userPassword, [name]: value });
    const error = { ...formError };
    switch (name) {
      case "newPassword":
        error.newPassword =
          value.trim() === ""
            ? "Enter new password."
            : !value.match(
                // eslint-disable-next-line
                /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
              )
            ? "Atleast one number, alphabet and one special character are required."
            : "";
        break;
      case "confirmPassword":
        error.confirmPassword =
          value.trim() === ""
            ? "Confirm Password required."
            : value !== userPassword.newPassword
            ? "Confirm password not matched."
            : "";
        break;
      default:
        break;
    }
    setFormError({ ...error });
  };
  const validate = () => {
    let error = true;
    const submitError = { ...formError };
    if (userPassword.newPassword === "") {
      error = false;
      submitError.newPassword = "New Password Required.";
    } else if (userPassword.newPassword.length < 4) {
      error = false;
      submitError.newPassword = "New Password must be more than 4 characters.";
    }
    if (userPassword.confirmPassword === "") {
      error = false;
      submitError.confirmPassword = "Confirm Password is Required.";
    } else if (userPassword.confirmPassword !== userPassword.newPassword) {
      error = false;
      submitError.confirmPassword =
        "Password and confirm password not matched.";
    }
    setFormError({ ...submitError });
    return error;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        password: userPassword.newPassword,
        emailToken: search.substring(12),
      };
      try {
        setLoading(true);
        const response = await postReqwithoutHeader("resetPassword", data);
        const { status, message } = response.data;
        if (status === 200) {
          setLoading(false);
          navigate("/sign-in");
          localStorage.clear();
          toast.success(message, {
            theme: "colored",
            autoClose: 950,
          });
        } else {
          toast.error(message, {
            theme: "colored",
            autoClose: 950,
          });
          setLoading(false);
        }
      } catch (error) {
        const { message } = error.response.data;
        toast.error(message, { theme: "colored", autoClose: 950 });
        setLoading(false);
      }
    } else {
      toast.error("Error.");
      setLoading(false);
    }
  };
  const delayCall = debounce(handleSubmit, 1500);

  const showPassword = () => {
    var temp = document.getElementById("confirmPassword");
    var eye = document.getElementById("eyeIcon");
    if (temp.type === "password") {
      temp.type = "text";
      eye.classList = "fa fa-eye";
    } else {
      temp.type = "password";
      eye.classList = "fa fa-eye-slash";
    }
  };
  return (
    <React.Fragment>
      <section className="form-main login-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="form-body custom-width">
                <div className="form-body-main">
                  <div className="logo bg-transparent">
                    <img src={loginLogo} alt="loginLogo" />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="New Password"
                      aria-required="true"
                      onChange={handleChange}
                      value={userPassword?.newPassword}
                      name="newPassword"
                      required
                    />
                    <p className="errorPara">{formError?.newPassword}</p>
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      className="form-control "
                      placeholder="Confirm Password"
                      aria-required="true"
                      onChange={handleChange}
                      value={userPassword?.confirmPassword}
                      required
                    />
                    <p className="errorPara">{formError?.confirmPassword}</p>
                    <span className="formIcon" onClick={showPassword}>
                      <i className="fa fa-eye-slash" id="eyeIcon" />
                    </span>
                  </div>
                  <div className="form-group">
                    <button className="login-btn" onClick={delayCall}>
                      {loading ? <SpinnerComponent /> : "Reset Password"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default ResetPassword;
