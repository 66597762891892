import React, { useRef, useState } from "react";
import "./CustomerForm.css";
import logo from "./logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import {
  ADDRESS_MSG,
  CITY_NAME_MSG,
  CUSTOMER_NUM_MSG,
  EMAIL_REQUIRED_MSG,
  NAME_REQUIRED_MSG,
  ONLY_FIVE_NUMBER_MSG,
  ONLY_TEN_NUMBER_ALLOW,
  PHONE_REQUIRED_MSG,
  SELECT_ANY_VALUE,
  STATE_NAME_MSG,
  TAX_ID_MSG,
  VALID_EMAIL_MSG,
  VALID_MOBILE_NUMBER,
  ZIPCODE_MSG,
} from "../utils/validationmsg";
import { postReqwithoutHeader } from "../utils/constant";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
// import SignatureField from "../components/SignatureField";

const CustomerForm = () => {
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formData, setFormData] = useState({
    customerName: "",
    mobileNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    b2bTaxID: "",
    isCokeCustomer: "",
    cokeCN: "",
    isSignupForRedRewards: "",
  });
  const [formError, setFormError] = useState({
    customerName: "",
    mobileNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    b2bTaxID: "",
    isCokeCustomer: "",
    cokeCN: "",
    isSignupForRedRewards: "",
  });
  const [captchaToken, setCaptchaToken] = useState("");
  // const siteKey = "6LftOEkqAAAAAK0gg6kL6PoRkvpzbCbrOZ_BPCd5"; // Site key for AWS QA
  // const siteKey = "6LdgNkoqAAAAAKczxjBaMsBGwwG3nU7KyCCEEpv_"; // Site key for Chetu QA
  // const siteKey = "6LfDplQqAAAAAE0fwHv2SUJsQn4VlGEFykbx3HCz"; // Site key for AWS Staging
  const siteKey = "6LcDSDAqAAAAAADBG7GeSQMWq5EW2zTITSZPXk65"; // Site key for AWS Production

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    const error = { ...formError };
    switch (name) {
      case "customerName":
        error.customerName = value.trim() === "" ? NAME_REQUIRED_MSG : "";
        break;
      case "email":
        error.email =
          value.trim() === ""
            ? EMAIL_REQUIRED_MSG
            : !value.match(
                // eslint-disable-next-line
                /^(?![0-9]+@)\w+([\.-]?\w+)*@[a-zA-Z0-9-][a-zA-Z0-9-][a-zA-Z0-9-]+(?:\.[a-zA-Z][a-zA-Z]+)$/
              )
            ? VALID_EMAIL_MSG
            : "";
        break;
      case "address":
        error.address = value.trim() === "" ? ADDRESS_MSG : "";
        break;
      case "city":
        error.city = value.trim() === "" ? CITY_NAME_MSG : "";
        break;
      case "state":
        error.state = value.trim() === "" ? STATE_NAME_MSG : "";
        break;
      case "zipCode":
        error.zipCode =
          value === ""
            ? ZIPCODE_MSG
            : value.length === 5
            ? ""
            : ONLY_FIVE_NUMBER_MSG;
        break;
      case "cokeCN":
        error.cokeCN = value === "" ? CUSTOMER_NUM_MSG : "";
        break;
      case "b2bTaxID":
        error.b2bTaxID = value === "" ? TAX_ID_MSG : "";
        break;
      case "isCokeCustomer":
        error.isCokeCustomer = value === "" ? SELECT_ANY_VALUE : "";
        break;
      case "isSignupForRedRewards":
        error.isSignupForRedRewards = value === "" ? SELECT_ANY_VALUE : "";
        break;
      default:
        break;
    }
    setFormError(error);
  };
  const convertMobileNumber = (e) => {
    const { name, value } = e.target;
    let myVal = value.replace(/\D/g, "");
    const error = { ...formError };
    switch (name) {
      case "mobileNumber":
        error.mobileNumber =
          myVal === ""
            ? PHONE_REQUIRED_MSG
            : myVal.length > 10
            ? ONLY_TEN_NUMBER_ALLOW
            : myVal.length === 10
            ? ""
            : VALID_MOBILE_NUMBER;
        break;
      default:
        break;
    }
    setFormError(error);
    if (myVal.length === 10) {
      const intlCode = `${myVal.slice(0, 3)}-${myVal.slice(3, 6)}-${myVal.slice(
        6,
        10
      )}`;
      setPhoneNumber(intlCode);
    } else {
      setPhoneNumber(myVal);
    }
    setFormData({
      ...formData,
      mobileNumber: myVal,
    });
  };
  const validateValue = (e) => {
    const pattern = /^[0-9\b]+$/;
    if (e.target.value === "" || pattern.test(e.target.value)) {
      handleChange(e);
    } else {
      e.preventDefault();
      e.target.value = e.target.value.slice(0, -1);
    }
  };
  const validate = () => {
    let error = true;
    const submitError = { ...formError };
    if (formData.customerName === "") {
      error = false;
      submitError.customerName = NAME_REQUIRED_MSG;
    }
    if (formData.mobileNumber === "") {
      error = false;
      submitError.mobileNumber = PHONE_REQUIRED_MSG;
    } else if (formData.mobileNumber.length !== 10) {
      error = false;
      submitError.mobileNumber = "Mobile Number should be 10 digit.";
    }
    if (formData.email === "") {
      error = false;
      submitError.email = EMAIL_REQUIRED_MSG;
    } else if (
      !formData.email.match(
        // eslint-disable-next-line
        /^(?![0-9]+@)\w+([\.-]?\w+)*@[a-zA-Z0-9-][a-zA-Z0-9-][a-zA-Z0-9-]+(?:\.[a-zA-Z][a-zA-Z]+)$/
      )
    ) {
      error = false;
      submitError.email = VALID_EMAIL_MSG;
    }
    if (formData.address === "") {
      error = false;
      submitError.address = "Customer address is required.";
    }
    if (formData.city === "") {
      error = false;
      submitError.city = "City is required.";
    }
    if (formData.state === "") {
      error = false;
      submitError.state = "State is required.";
    }
    if (formData.zipCode === "") {
      error = false;
      submitError.zipCode = "Zip Code is required.";
    }
    if (formData.b2bTaxID === "") {
      error = false;
      submitError.b2bTaxID = "TAX ID is required.";
    }
    if (formData.isCokeCustomer === "") {
      error = false;
      submitError.isCokeCustomer = "Select any option for Coke Customer.";
    }
    if (formData.isCokeCustomer === "yes") {
      if (formData.cokeCN === "") {
        error = false;
        submitError.cokeCN = "Customer number is required.";
      }
      if (formData.isSignupForRedRewards === "") {
        error = false;
        submitError.isSignupForRedRewards =
          "Please select an option for Red Rewards.";
      }
    }
    setFormError(submitError);
    return error;
  };
  const recaptchaRef = useRef(null);
  const onChange = (value) => {
    setCaptchaToken(value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaToken) {
      toast.error("Please complete the CAPTCHA verification.", {
        theme: "colored",
        autoClose: 800,
      });
      return;
    }
    if (validate()) {
      const {
        customerName,
        mobileNumber,
        email,
        address,
        city,
        state,
        zipCode,
        b2bTaxID,
        isCokeCustomer,
        cokeCN,
        isSignupForRedRewards,
      } = formData;
      const reqBody = {
        customerName,
        mobileNumber,
        email,
        address,
        city,
        state,
        zipCode,
        b2bTaxID,
      };
      if (isCokeCustomer === "yes") {
        reqBody.isCokeCustomer = true;
        reqBody.cokeCN = cokeCN;
      } else {
        reqBody.isCokeCustomer = false;
      }
      if (isSignupForRedRewards === "yes") {
        reqBody.isSignupForRedRewards = true;
      } else if (isSignupForRedRewards === "no") {
        reqBody.isSignupForRedRewards = false;
      }
      setLoading(true);
      try {
        const response = await postReqwithoutHeader(
          `web/addCustomerDetails`,
          reqBody
        );
        const { status, message } = response?.data;
        if (status === 200) {
          setLoading(false);
          toast.success(message, { theme: "colored", autoClose: 800 });
          setCaptchaToken("");
          if (recaptchaRef.current && recaptchaRef.current.reset) {
            recaptchaRef.current.reset();
          }
          setPhoneNumber("");
          setFormData({
            customerName: "",
            mobileNumber: "",
            email: "",
            address: "",
            city: "",
            state: "",
            zipCode: "",
            b2bTaxID: "",
            cokeCN: "",
            isSignupForRedRewards: "",
          });
          setFormError({
            customerName: "",
            mobileNumber: "",
            email: "",
            address: "",
            city: "",
            state: "",
            zipCode: "",
            b2bTaxID: "",
            isCokeCustomer: "",
            cokeCN: "",
            isSignupForRedRewards: "",
          });
        }
      } catch (error) {
        setLoading(false);
        const { message } = error.response.data;
        toast.error(message, { theme: "colored", autoClose: 800 });
      }
    } else {
      toast.error("Please fill the required fields.", {
        theme: "colored",
        autoClose: 800,
      });
    }
  };

  const delaySubmit = debounce(handleSubmit, 1300);

  return (
    <div>
      <>
        <header>
          <div className="formheader">
            <div className="formlogo">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </header>
        <div className="query-form-wrp">
          <h4 className="heading-form">Customer Form</h4>
          <form className="form-wrp-box">
            <div className="cstm-row">
              <div className="half-wrp">
                <label>Name</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="customerName"
                  value={formData?.customerName}
                  onChange={handleChange}
                  type="text"
                  maxLength={50}
                  placeholder="Enter your name"
                />
                <p className="errorPara">{formError.customerName}</p>
              </div>
              <div className="half-wrp">
                <label>Email</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="email"
                  value={formData?.email}
                  onChange={handleChange}
                  type="email"
                  placeholder="Enter your email"
                />
                <p className="errorPara">{formError.email}</p>
              </div>
              <div className="half-wrp">
                <label>Mobile</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="mobileNumber"
                  value={phoneNumber}
                  onChange={(e) => convertMobileNumber(e)}
                  type="text"
                  maxLength={12}
                  placeholder="Enter your mobile number"
                />
                <p className="errorPara">{formError.mobileNumber}</p>
              </div>
              <div className="half-wrp">
                <label>Address</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="address"
                  value={formData?.address}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter your address"
                />
                <p className="errorPara">{formError.address}</p>
              </div>
              <div className="half-wrp">
                <label>City</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="city"
                  value={formData?.city}
                  maxLength={30}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter your city"
                />
                <p className="errorPara">{formError.city}</p>
              </div>
              <div className="half-wrp">
                <label>State</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="state"
                  value={formData?.state}
                  maxLength={25}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter your state"
                />
                <p className="errorPara">{formError.state}</p>
              </div>
              <div className="half-wrp">
                <label>Zip Code</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="zipCode"
                  value={formData?.zipCode}
                  onChange={validateValue}
                  type="text"
                  maxLength={5}
                  placeholder="Enter your zip code"
                />
                <p className="errorPara">{formError.zipCode}</p>
              </div>
              <div className="half-wrp">
                <label>TAX ID</label>
                <span style={{ color: "red" }}>*</span>
                <input
                  name="b2bTaxID"
                  value={formData?.b2bTaxID}
                  onChange={handleChange}
                  type="text"
                  maxLength={15}
                  placeholder="Enter TAX ID"
                />
                <p className="errorPara">{formError.b2bTaxID}</p>
              </div>
              <div className="half-wrp">
                <label>Are you a Coke Customer?</label>
                <span style={{ color: "red" }}>*</span>
                <select
                  name="isCokeCustomer"
                  value={formData?.isCokeCustomer}
                  onChange={handleChange}
                  className="form-select"
                >
                  <option value="">Select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                <p className="errorPara">{formError.isCokeCustomer}</p>
              </div>
              {formData.isCokeCustomer === "yes" &&
                formData.isCokeCustomer !== "" &&
                formData.isCokeCustomer !== "no" && (
                  <div className="half-wrp">
                    <label>What is your Coke CN?</label>
                    <span style={{ color: "red" }}>*</span>
                    <input
                      name="cokeCN"
                      value={formData?.cokeCN}
                      onChange={validateValue}
                      type="text"
                      maxLength={10}
                      placeholder="Enter your customer number"
                    />
                    <p className="errorPara">{formError.cokeCN}</p>
                  </div>
                )}
              {formData.isCokeCustomer === "yes" && (
                <div className="half-wrp">
                  <label>Would you like to sign up for Red Rewards?</label>
                  <span style={{ color: "red" }}>*</span>
                  <select
                    name="isSignupForRedRewards"
                    value={formData?.isSignupForRedRewards}
                    onChange={handleChange}
                    className="form-select"
                  >
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  <p className="errorPara">{formError.isSignupForRedRewards}</p>
                </div>
              )}
              <div className="half-wrp">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={siteKey}
                  onChange={onChange}
                  value={captchaToken}
                />
              </div>
              {/* <div className="half-wrp">
                <SignatureField />
              </div> */}
              <div className="full-wrp submit-btn-wrp">
                <button type="button" onClick={delaySubmit} disabled={loading}>
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    </div>
  );
};
export default CustomerForm;
// import React from "react";
// import "./styleNew.css";
// import logo from "./logo2.png";

// const CustomerForm = () => {
//   return (
//     <div>
//       <section className="signle-pageForm">
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-lg-7">
//               <div className="mainLogo-box">
//                 {/* <img src="assets/img/logo2.png" className="img-fluid" alt="" /> */}
//                 <img src={logo} alt="logo" />
//               </div>
//               <div className="formCard">
//                 <div className="cardHeader">
//                   <h4 className="cardMain-head">New Customer Form</h4>
//                 </div>
//                 <div className="cardForm-input">
//                   <div className="row">
//                     <div className="col-lg-6 mb-3">
//                       <div className="inputField-grp">
//                         <label
//                           htmlFor=""
//                           className="form-label custmform-label"
//                         >
//                           BUSINESS NAME
//                           <sup className="text-danger">*</sup>
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control custminputField"
//                           id=""
//                           aria-describedby=""
//                         />
//                         <span className="text-danger">
//                           Enter a value for this field.
//                         </span>
//                         <div id="" className="form-text fieldInfo-txt mt-0">
//                           Name of Business
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="inputField-grp">
//                         <label
//                           htmlFor=""
//                           className="form-label custmform-label"
//                         >
//                           Business Address
//                           <sup className="text-danger">*</sup>
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control custminputField"
//                           id=""
//                           aria-describedby=""
//                         />
//                         <span className="text-danger d-none">
//                           Enter a value for this field.
//                         </span>
//                         <div id="" className="form-text fieldInfo-txt mt-0">
//                           Street Address
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="inputField-grp">
//                         <input
//                           type="text"
//                           className="form-control custminputField"
//                           id=""
//                           aria-describedby=""
//                         />
//                         <span className="text-danger d-none">
//                           Enter a value for this field.
//                         </span>
//                         <div id="" className="form-text fieldInfo-txt mt-0">
//                           Address Line 2
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-6 mb-3">
//                       <div className="inputField-grp">
//                         <input
//                           type="text"
//                           className="form-control custminputField"
//                           id=""
//                           aria-describedby=""
//                         />
//                         <span className="text-danger d-none">
//                           Enter a value for this field.
//                         </span>
//                         <div id="" className="form-text fieldInfo-txt mt-0">
//                           City
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-6 mb-3">
//                       <div className="inputField-grp">
//                         <input
//                           type="text"
//                           className="form-control custminputField"
//                           id=""
//                           aria-describedby=""
//                         />
//                         <span className="text-danger d-none">
//                           Enter a value for this field.
//                         </span>
//                         <div id="" className="form-text fieldInfo-txt mt-0">
//                           State/Region/Province
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-6 mb-3">
//                       <div className="inputField-grp">
//                         <input
//                           type="text"
//                           className="form-control custminputField"
//                           id=""
//                           aria-describedby=""
//                         />
//                         <span className="text-danger d-none">
//                           Enter a value for this field.
//                         </span>
//                         <div id="" className="form-text fieldInfo-txt mt-0">
//                           Postal / Zip Code
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-6 mb-3">
//                       <div className="inputField-grp">
//                         <input
//                           type="text"
//                           className="form-control custminputField"
//                           id=""
//                           aria-describedby=""
//                         />
//                         <span className="text-danger d-none">
//                           Enter a value for this field.
//                         </span>
//                         <div id="" className="form-text fieldInfo-txt mt-0">
//                           Country
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="inputField-grp">
//                         <label
//                           htmlFor=""
//                           className="form-label custmform-label"
//                         >
//                           Business Phone Number
//                           <sup className="text-danger">*</sup>
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control custminputField"
//                           id=""
//                           aria-describedby=""
//                         />
//                         <span className="text-danger d-none">
//                           Enter a value for this field.
//                         </span>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="inputField-grp">
//                         <label
//                           htmlFor=""
//                           className="form-label custmform-label"
//                         >
//                           Applicant Name
//                           <sup className="text-danger">*</sup>
//                         </label>
//                       </div>
//                       <div className="row">
//                         <div className="col-lg-6">
//                           <div className="inputField-grp">
//                             <input
//                               type="text"
//                               className="form-control custminputField"
//                               id=""
//                               aria-describedby=""
//                             />
//                             <span className="text-danger d-none">
//                               Enter a value for this field.
//                             </span>
//                             <div id="" className="form-text fieldInfo-txt mt-0">
//                               First Name
//                             </div>
//                           </div>
//                         </div>
//                         <div className="col-lg-6">
//                           <div className="inputField-grp">
//                             <input
//                               type="text"
//                               className="form-control custminputField"
//                               id=""
//                               aria-describedby=""
//                             />
//                             <span className="text-danger d-none">
//                               Enter a value for this field.
//                             </span>
//                             <div id="" className="form-text fieldInfo-txt mt-0">
//                               Last Name{" "}
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="row">
//                         <div className="col-lg-6">
//                           <div className="inputField-grp">
//                             <label
//                               htmlFor=""
//                               className="form-label custmform-label"
//                             >
//                               Applicant Mobile Phone Number
//                               <sup className="text-danger">*</sup>
//                             </label>
//                             <input
//                               type="text"
//                               className="form-control custminputField"
//                               id=""
//                               aria-describedby=""
//                             />
//                             <span className="text-danger d-none">
//                               Enter a value for this field.
//                             </span>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="row">
//                         <div className="col-lg-6">
//                           <div className="inputField-grp">
//                             <label
//                               htmlFor=""
//                               className="form-label custmform-label"
//                             >
//                               Applicant Email Address
//                               <sup className="text-danger">*</sup>
//                             </label>
//                             <input
//                               type="text"
//                               className="form-control custminputField"
//                               id=""
//                               aria-describedby=""
//                             />
//                             <span className="text-danger d-none">
//                               Enter a value for this field.
//                             </span>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="row">
//                         <div className="col-lg-6">
//                           <div className="inputField-grp">
//                             <label
//                               htmlFor=""
//                               className="form-label custmform-label"
//                             >
//                               Are you a Coke Customer?
//                               <sup className="text-danger">*</sup>
//                             </label>
//                             <div className="d-flex gap-3 inputField-grp">
//                               <div className="form-check">
//                                 <input
//                                   className="form-check-input"
//                                   type="radio"
//                                   name="cokeCustm"
//                                   id=""
//                                 />
//                                 <label className="form-check-label" htmlFor="">
//                                   Yes
//                                 </label>
//                               </div>
//                               <div className="form-check">
//                                 <input
//                                   className="form-check-input"
//                                   type="radio"
//                                   name="cokeCustm"
//                                   id=""
//                                 />
//                                 <label className="form-check-label" htmlFor="">
//                                   No
//                                 </label>
//                               </div>
//                             </div>
//                             <span className="text-danger d-none">
//                               Enter a value for this field.
//                             </span>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="inputField-grp">
//                         <label
//                           htmlFor=""
//                           className="form-label custmform-label"
//                         >
//                           Does your Business buy directly from Coke Florida or
//                           have an existing agreement with them?
//                           <sup className="text-danger">*</sup>
//                         </label>
//                         <select
//                           className="form-select"
//                           multiple=""
//                           aria-label="Multiple select example"
//                         >
//                           <option>Select option</option>
//                           <option value={1}>Yes</option>
//                           <option value={2}>No</option>
//                         </select>
//                         <span className="text-danger d-none">
//                           Enter a value for this field.
//                         </span>
//                         <div id="" className="form-text fieldInfo-txt mt-0">
//                           (Does Coke deliver to your store or do you have a
//                           contract with them?){" "}
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="inputField-grp">
//                         <label
//                           htmlFor=""
//                           className="form-label custmform-label"
//                         >
//                           IF YES - Please provide Account/Outlet Number
//                         </label>
//                       </div>
//                       <div className="row">
//                         <div className="col-lg-6">
//                           <div className="inputField-grp">
//                             <input
//                               type="text"
//                               className="form-control custminputField"
//                               id=""
//                               aria-describedby=""
//                             />
//                             <span className="text-danger d-none">
//                               Enter a value for this field.
//                             </span>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="inputField-grp">
//                         <label
//                           htmlFor=""
//                           className="form-label custmform-label"
//                         >
//                           IF NO -Would you like to be enrolled in Coke Florida's
//                           Red Rewards program to earn store credits for
//                           purchases at Xpress Cash &amp; Carry? Terms and
//                           Conditions can be found HERE:{" "}
//                           <a href="#" className="labeltxt">
//                             cokeflorida.com/xpress-redrewards/
//                           </a>
//                         </label>
//                       </div>
//                       <div className="row">
//                         <div className="col-lg-6">
//                           <div className="inputField-grp">
//                             <input
//                               type="text"
//                               className="form-control custminputField"
//                               id=""
//                               aria-describedby=""
//                             />
//                             <span className="text-danger d-none">
//                               Enter a value for this field.
//                             </span>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="inputField-grp">
//                         <label
//                           htmlFor=""
//                           className="form-label custmform-label"
//                         >
//                           Where are you currently purchasing Coca Cola Product
//                           from today?
//                         </label>
//                         <input
//                           type="text"
//                           className="form-control custminputField"
//                           id=""
//                           aria-describedby=""
//                         />
//                         <span className="text-danger d-none">
//                           Enter a value for this field.
//                         </span>
//                         <div id="" className="form-text fieldInfo-txt mt-0">
//                           Example: Club Store, Restaurant Depot, Supermarkets,
//                           Direct from Manufacturer, Other Distributor.
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="inputField-grp">
//                         <label
//                           htmlFor=""
//                           className="form-label custmform-label"
//                         >
//                           SALES TAX CERTIFICATE
//                         </label>
//                         {/* <input type="text" class="form-control custminputField" id=""
//                                       aria-describedby=""> */}
//                         <div className="imgUpload-box">
//                           <div className="imgUpload-optn">
//                             <span className="textImguplod">Choose-file</span>
//                             <div className="impgUploadbtn-grp">
//                               <label
//                                 htmlFor="imgupload-btn"
//                                 className=" btnUpload"
//                               >
//                                 <input type="file" id="imgupload-btn" />
//                                 <span className="iconimg">
//                                   <svg
//                                     xmlns="http://www.w3.org/2000/svg"
//                                     width={16}
//                                     height={16}
//                                     fill="currentColor"
//                                     className="bi bi-upload"
//                                     viewBox="0 0 16 16"
//                                   >
//                                     <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
//                                     <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
//                                   </svg>
//                                 </span>
//                               </label>
//                               <a className="btnUpload">
//                                 <span className="iconimg">
//                                   <svg
//                                     xmlns="http://www.w3.org/2000/svg"
//                                     width={16}
//                                     height={16}
//                                     fill="currentColor"
//                                     className="bi bi-camera"
//                                     viewBox="0 0 16 16"
//                                   >
//                                     <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z" />
//                                     <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
//                                   </svg>
//                                 </span>
//                               </a>
//                             </div>
//                           </div>
//                         </div>
//                         <span className="text-danger d-none">
//                           Enter a value for this field.
//                         </span>
//                         <div id="" className="form-text fieldInfo-txt mt-0">
//                           Upload Photo of Sales Tax Certificate
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="inputField-grp">
//                         <label
//                           htmlFor=""
//                           className="form-label custmform-label"
//                         >
//                           IF PAYING BY CHECK - UPLOAD COPY OF DRIVER LICENSE +
//                           VOIDED CHECK
//                         </label>
//                         {/* <input type="text" class="form-control custminputField" id=""
//                                       aria-describedby=""> */}
//                         <div className="imgUpload-box">
//                           <div className="imgUpload-optn">
//                             <span className="textImguplod">Choose-file(s)</span>
//                             <div className="impgUploadbtn-grp">
//                               <label
//                                 htmlFor="imgupload-btn"
//                                 className=" btnUpload"
//                               >
//                                 <input type="file" id="imgupload-btn" />
//                                 <span className="iconimg">
//                                   <svg
//                                     xmlns="http://www.w3.org/2000/svg"
//                                     width={16}
//                                     height={16}
//                                     fill="currentColor"
//                                     className="bi bi-upload"
//                                     viewBox="0 0 16 16"
//                                   >
//                                     <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
//                                     <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
//                                   </svg>
//                                 </span>
//                               </label>
//                               <a className="btnUpload">
//                                 <span className="iconimg">
//                                   <svg
//                                     xmlns="http://www.w3.org/2000/svg"
//                                     width={16}
//                                     height={16}
//                                     fill="currentColor"
//                                     className="bi bi-camera"
//                                     viewBox="0 0 16 16"
//                                   >
//                                     <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4z" />
//                                     <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
//                                   </svg>
//                                 </span>
//                               </a>
//                             </div>
//                           </div>
//                         </div>
//                         <span className="text-danger d-none">
//                           Enter a value for this field.
//                         </span>
//                         <div id="" className="form-text fieldInfo-txt mt-0">
//                           Please upload BOTH a copy of Driver's License, and a
//                           copy of a voided check
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="inputField-grp">
//                         <label
//                           htmlFor=""
//                           className="form-label custmform-label"
//                         >
//                           Terms and Conditions
//                           <sup className="text-danger">*</sup>
//                         </label>
//                         <div className="termsContainer">
//                           <div className="terms-head">Terms and Conditions</div>
//                           <div className="termsitem">
//                             <span className="termLine-head">
//                               1. Acceptance of Terms
//                             </span>
//                             <p>
//                               By submitting a customer application to XPRESS
//                               Cash &amp; Carry, you agree to abide by these
//                               Terms and Conditions. These terms may be updated
//                               periodically, and it is your responsibility to
//                               review them for any changes.
//                             </p>
//                           </div>
//                           <div className="termsitem">
//                             <span className="termLine-head">2.Eligibility</span>
//                             <p>
//                               To apply for an account with XPRESS Cash &amp;
//                               Carry, you must be at least 18 years old and
//                               authorized to conduct business on behalf of the
//                               entity or organization you represent. XPRESS Cash
//                               &amp; Carry reserves the right to accept or reject
//                               any application at its discretion.
//                             </p>
//                           </div>
//                           <div className="termsitem">
//                             <span className="termLine-head">
//                               3. Information Accuracy
//                             </span>
//                             <p>
//                               You agree to provide accurate and complete
//                               information in your application. Should any
//                               information provided in your application change,
//                               you are responsible for notifying XPRESS Cash
//                               &amp; Carry of these changes immediately.
//                             </p>
//                           </div>
//                           <div className="termsitem">
//                             <span className="termLine-head">
//                               4. Account Approval
//                             </span>
//                             <p>
//                               Submission of an application does not guarantee
//                               approval. XPRESS Cash &amp; Carry will review your
//                               application and may request additional information
//                               before granting account access.
//                             </p>
//                           </div>
//                           <div className="termsitem">
//                             <span className="termLine-head">
//                               5. Account Usage
//                             </span>
//                             <p>
//                               Approved customers will receive an account with
//                               XPRESS Cash &amp; Carry. This account is strictly
//                               for business purposes and is non-transferable. You
//                               are responsible for maintaining the
//                               confidentiality of your account information and
//                               are liable for all activities that occur under
//                               your account.
//                             </p>
//                           </div>
//                           <div className="termsitem">
//                             <span className="termLine-head">
//                               6. Payment Terms
//                             </span>
//                             <p>
//                               All purchases made through XPRESS Cash &amp; Carry
//                               are subject to our payment terms. Payments must be
//                               made according to the agreed-upon terms and within
//                               the specified payment period. Late payments may
//                               incur interest and could result in account
//                               suspension or termination.
//                             </p>
//                           </div>
//                           <div className="termsitem">
//                             <span className="termLine-head">
//                               7. Pricing and Availability
//                             </span>
//                             <p>
//                               XPRESS Cash &amp; Carry reserves the right to
//                               adjust pricing and product availability at any
//                               time without prior notice. All prices are
//                               exclusive of taxes, which will be applied as per
//                               applicable laws.
//                             </p>
//                           </div>
//                           <div className="termsitem">
//                             <span className="termLine-head">
//                               8. Returns and Refunds
//                             </span>
//                             <p>
//                               Our return and refund policies are outlined
//                               separately and are incorporated into these Terms
//                               and Conditions. By accepting these terms, you
//                               acknowledge that you have read and understood our
//                               return and refund policies.
//                             </p>
//                           </div>
//                           <div className="termsitem">
//                             <span className="termLine-head">
//                               9. Limitation of Liability
//                             </span>
//                             <p>
//                               XPRESS Cash &amp; Carry is not liable for any
//                               direct, indirect, incidental, special, or
//                               consequential damages arising from your use of the
//                               account or products purchased through the account,
//                               even if advised of the possibility of such
//                               damages.
//                             </p>
//                           </div>
//                           <div className="termsitem">
//                             <span className="termLine-head">
//                               10. Termination
//                             </span>
//                             <p>
//                               XPRESS Cash &amp; Carry reserves the right to
//                               terminate your account at any time, with or
//                               without cause. Upon termination, all outstanding
//                               balances must be paid immediately.
//                             </p>
//                           </div>
//                           <div className="termsitem">
//                             <span className="termLine-head">
//                               11. Governing Law
//                             </span>
//                             <p>
//                               These Terms and Conditions are governed by and
//                               construed in accordance with the laws of
//                               Florida/United States, without regard to its
//                               conflict of law provisions.
//                             </p>
//                           </div>
//                           <div className="termsitem">
//                             <span className="termLine-head">
//                               12. Dispute Resolution
//                             </span>
//                             <p>
//                               Any disputes arising from these Terms and
//                               Conditions or your use of the XPRESS Cash &amp;
//                               Carry account will be resolved through binding
//                               arbitration in Tampa,Florida/United States
//                             </p>
//                           </div>
//                           <div className="termsitem">
//                             <span className="termLine-head">
//                               13. Contact Information
//                             </span>
//                             <p>
//                               For any questions or concerns regarding these
//                               Terms and Conditions, please contact XPRESS Cash
//                               &amp; Carry at customers@xpressdistributors.com
//                             </p>
//                           </div>
//                         </div>
//                         <span className="text-danger d-none">
//                           Enter a value for this field.
//                         </span>
//                         <div id="" className="form-text mt-0 inputField-grp">
//                           <div className="form-check">
//                             <input
//                               className="form-check-input me-2"
//                               type="checkbox"
//                               defaultValue=""
//                               id=""
//                             />
//                             <label className="form-check-label" htmlFor="">
//                               I accept the Terms and Conditions.
//                             </label>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="inputField-grp">
//                         <label
//                           htmlFor=""
//                           className="form-label custmform-label"
//                         >
//                           XPRESS Cash &amp; Carry Text Updates Terms and
//                           Conditions
//                         </label>
//                         <div className="termsContainer">
//                           <p className="termsitem">
//                             By providing your mobile number, you consent to
//                             receive periodic text messages from XPRESS Cash
//                             &amp; Carry regarding your account, orders,
//                             promotions, and other relevant information, with the
//                             understanding that message and data rates may apply,
//                             and you can opt-out at any time by replying "STOP."
//                           </p>
//                         </div>
//                       </div>
//                       <span className="text-danger d-none">
//                         Enter a value for this field.
//                       </span>
//                       <div id="" className="form-text mt-0 inputField-grp">
//                         <div className="form-check">
//                           <input
//                             className="form-check-input me-2"
//                             type="checkbox"
//                             defaultValue=""
//                             id=""
//                           />
//                           <label className="form-check-label" htmlFor="">
//                             I accept the Terms and Conditions.
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 mb-3">
//                       <div className="row ">
//                         <h3 className="sign_heading">Sign &amp; Print Name</h3>
//                         <div className="col-lg-6 mb-3">
//                           <div className="inputField-grp">
//                             <label
//                               htmlFor=""
//                               className="form-label custmform-label"
//                             >
//                               Print Number
//                               <sup className="text-danger">*</sup>
//                             </label>
//                             <input
//                               type="text"
//                               className="form-control custminputField"
//                               id=""
//                               aria-describedby=""
//                             />
//                             <span className="text-danger d-none">
//                               Enter a value for this field.
//                             </span>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="row">
//                         <div className="col-lg-12">
//                           <div className="inputField-grp signature">
//                             <label
//                               htmlFor=""
//                               className="form-label custmform-label"
//                             >
//                               Signature
//                               <sup className="text-danger">*</sup>
//                             </label>
//                             <input
//                               type="text"
//                               className="form-control custminputField"
//                               id=""
//                               aria-describedby=""
//                             />
//                             <span className="text-danger">
//                               <a href="#">Clear</a>
//                             </span>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-lg-12 text-center submit_btn1">
//                       <button>Submit</button>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default CustomerForm;
