export const setUserToken = (data) => {
  localStorage.setItem("userToken", JSON.stringify(data));
};
export const setUserData = (data) => {
  localStorage.setItem("userData", JSON.stringify(data));
};
export const getUserToken = () => {
  const token = localStorage.getItem("userToken") || "";
  return token;
};
export const getUserData = () => {
  const userData = localStorage.getItem("userData") || {};
  return userData;
};

export const setCustomDateType = (data) => {
  localStorage.setItem("customDate", JSON.stringify(data));
};
export const getCustomDateType = () => {
  const customDate = localStorage.getItem("customDate");
  return customDate;
};
