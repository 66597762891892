import React from "react";
import { Oval } from "react-loader-spinner";

const SpinnerComponent = () => {
  return (
    <Oval
      height={20}
      width={20}
      color="#ffffff"
      wrapperStyle={{ display: "inline-flex" }}
      wrapperclassName="spinner-cls"
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#000000"
      strokeWidth={3}
      strokeWidthSecondary={3}
    />
  );
};

export default SpinnerComponent;
