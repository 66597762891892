import "antd/dist/antd.min.css";
import "./App.css";
import "./Responsive.css";
import Routing from "./routing/Routing";
function App() {
  return (
    <div className="App">
      <Routing />
    </div>
  );
}
export default App;
