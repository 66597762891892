import React from "react";
import logo from "../assets/not-found.jpg";

import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <main>
      <div className="container">
        <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
          <img src={logo} alt="Page not found" />
          <h2>The page you are looking for doesn't exist.</h2>
          <Link to="/sign-in" className="btn">
            Go to login
          </Link>
        </section>
      </div>
    </main>
  );
};

export default NotFound;
