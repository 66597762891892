import {
  CLOCKINOUT_DATE,
  EMPLOYEE_CLOCKIN_DATE,
  EMPLOYEE_CLOCKIN_RANGE,
  EMPLOYEE_ID_FOR_CLOCKINREPORT,
  EMPLOYEE_NAME,
  MERCHANT_DATA,
  PURCHASE_ORDER_DATA,
  PURCHASE_ORDER_NUMBER,
} from "../constants";

export const merchantDataAction = (data) => {
  return {
    type: MERCHANT_DATA,
    payload: data,
  };
};
export const purchaseOrderDataAction = (data) => {
  return {
    type: PURCHASE_ORDER_DATA,
    payload: data,
  };
};
export const purchaseOrderNumberAction = (data) => {
  return {
    type: PURCHASE_ORDER_NUMBER,
    payload: data,
  };
};

export const employeeClockInOutDate = (data) => {
  return {
    type: EMPLOYEE_CLOCKIN_DATE,
    payload: data,
  };
};
export const employeeClockInOutRange = (data) => {
  return {
    type: EMPLOYEE_CLOCKIN_RANGE,
    payload: data,
  };
};

export const employeeIdForClockInOutReport = (data) => {
  return {
    type: EMPLOYEE_ID_FOR_CLOCKINREPORT,
    payload: data,
  };
};

export const employeeNameAction = (data) => {
  return {
    type: EMPLOYEE_NAME,
    payload: data,
  };
};

export const clockInOutDateAction = (data) => {
  return {
    type: CLOCKINOUT_DATE,
    payload: data,
  };
};
